<template>
	<div class="newsletter-form">
		<p>SUBSCRIBE TO OUR NEWSLETTER</p>
		<form 
			action="https://hope.us17.list-manage.com/subscribe/post?u=bffd2506597a3b38ca5a9cb37&amp;id=8ff2867b9c&amp;f_id=000a52e0f0" 
			method="post" 
			id="mc-embedded-subscribe-form" 
			name="mc-embedded-subscribe-form" 
			class="validate" 
			target="_blank" 
			
			data-toggle="validator">
			<div id="mc_embed_signup_scroll">
				<div class="mc-field-group">					
					<input type="email" placeholder="Enter your email" value="" name="EMAIL" class="required email input-newsletter" id="mce-EMAIL" required>
					<button type="submit">Subscribe Now <i class="ri-send-plane-line"></i></button>
					<div class="form-check" style="margin-top:8px">
						<input type="checkbox" class="form-check-input" id="checkme" required> 
						<label class="form-check-label" for="checkme">
							Accept our							
							<router-link to="/privacy-policy">
								<strong>Privacy Policy</strong>
							</router-link> 
						</label>
					</div>
					<span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
				</div>
				<div id="mce-responses" class="clear foot">
					<div class="response" id="mce-error-response" style="display:none"></div>
					<div class="response" id="mce-success-response" style="display:none"></div>
				</div>	<!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
				<div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_bffd2506597a3b38ca5a9cb37_8ff2867b9c" tabindex="-1" value=""></div>
				<!-- <div class="optionalParent">
					<div class="clear foot">
						<input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button">
					</div>
				</div> -->
			</div>
		</form>
	</div>
</template>

<script>
export default {

}
</script>