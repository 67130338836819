<template>
    <div id="contact-form">
        <div class="contact-area ptb-100 bg-f9f9f9">
            <div class="container">
                <div class="contact-form">
                    <!-- <div class="section-title">
                        <h2>Get In <span style="color: #FFBD59;">Touch!</span></h2>
                        <p>Contact us with your details & ready to start with us</p>
                    </div> -->
                    <div v-if="success" class="box-success">
                        Your message has been sent.
                    </div>
                    <div v-if="error" class="box-error">
                        Sorry, an error has occurred. Thank you try again later.
                        <br>
                        <a href="#" @click="try_again"><u>Try again</u></a>
                    </div>
                    <form id="contactForm" @submit="onSubmit" v-if="!success && !error">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <label>Name *</label>
                                    <input v-model="name" type="text" name="name" class="form-control" id="name" placeholder="Eg: Sarah Taylor" required>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <label>E-mail *</label>
                                    <input v-model="mail" type="mail" name="mail" class="form-control" id="mail" placeholder="hello@sarah.com" required>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label>Subject *</label>
                                    <input v-model="subject" type="text" name="msg_subject" class="form-control" id="msg_subject" placeholder="Enter your subject" required>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label>Message *</label>
                                    <textarea v-model="message" name="message" id="message" class="form-control" cols="30" rows="6" placeholder="Enter message..." required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="checkme" required>
                                    <label class="form-check-label" for="checkme">
                                        Accept 
                                        <router-link to="/terms-conditions">
                                            <strong>Terms of Services</strong>
                                        </router-link> 
                                        and 
                                        <router-link to="/privacy-policy">
                                            <strong>Privacy Policy</strong>
                                        </router-link> 
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <button type="submit" class="default-btn"><span class="ri-arrow-right-s-line"></span>Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'GetInTouch',
    data(){
        return {
            error: false,
            success: false,
            name: '',
            mail: '',
            phone: '',
            subject: '',
            message: '',
        }
    },
    methods : {
        onSubmit : function (event) {
            event.preventDefault()

            let values = {
                name : this.name,
                mail : this.mail,
                phone : this.phone,
                subject : this.subject,
                message : this.message
            }

            console.log('send', values)

            let wp = this.$wp

            wp.contact = wp.registerRoute( 'chauffeMarcel/v2', '/contact/(?P<send>)' );

            wp.contact().send( JSON.stringify( values ) )
            .then( ( response ) => {
              
              console.log('mail', response);
              
              if( response == false ) 
              {
                console.log('error');
                this.error = true
              }
              else
              {
                console.log('success');
                this.success = true
                window.scrollTo(0, 300)
              }
            });
        },
        try_again : function () {
            this.error = false
        }
    }
}
</script>

<style type="text/css">
    .box-success, .box-error {

    }
</style>