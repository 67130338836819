<template>
    <div>
        <NavbarTwo />
        <div class="page-title-area">
            <div class="container">
                <div class="page-title-content text-center">
                    <h1><span style="color:#FFBD59">Glossary</span></h1>
                </div>
            </div>
        </div>
        <div class="case-studies-area pt-100 pb-70">
            <div class="container">
                <div class="case-studies-list">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" >Term</th>
                                    <th scope="col">Definition</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(post, index) in posts" :key="index">
                                    <td class="title">
                                        <div class="hancor" :id="post.post_name" ></div>
                                        <a :href="'#'+post.post_name" v-html="post.post_title"></a>
                                    </td>
                                    <td v-html="post.post_content"></td>  
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 100px;"></div>
    </div>
</template>

<script type="text/javascript">
import NavbarTwo from '../Layout/NavbarTwoSafest'
import $ from "jquery";

export default {
    components: {
        NavbarTwo,
    },
    data(){
        return {
            posts: []
        }
    },
    mounted () {
        this.wp_post('easy_api/v2/glossary', {}, (data)=>{
            this.posts = data
            console.log('revisions', data);

            setTimeout( () => {
                if( window.location.hash )
                {
                    $(document).scrollTop( $(window.location.hash).offset().top)
                }
            }, 100 )
        })
    }
}
</script>

<style>

    tbody .title .hancor {
        position: absolute;
        margin-top: -150px;
    }
    tbody .title {
        font-weight: bold;
        color: #155B8D;
    }
    tbody tr td p{
        font-size: 14px;
    }
</style>