/*function q(query) {
	return document.querySelector(query)
}*/

export default function(/*metas = {} */) {

	
	//let meta
	/*q('meta[property="og:url"]').setAttribute('content', window.location.href)

	for (let [key, value] of Object.entries(metas)) 
	{
		value = value.replace(/<[^>]*>?/gm, '')
		switch(key) {
			case 'title':
				q('title').innerHTML = value
				q('meta[property="og:title"]').setAttribute('content', value)
				break;
			case 'description':
				q('meta[name="description"]').setAttribute('content', value)
				q('meta[property="og:description"]').setAttribute('content', value)
				break;
			case 'image':
				q('meta[property="og:image"]').setAttribute('content', value)
				break;
			default:
				q('meta[name="'+key+'"]').setAttribute('content', value)
		}
	}*/
	
	
}