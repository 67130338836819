<template>
	<div class="safest-home-banner">
		<div class="wrap">
			<div class="container">
				Working <br>
				to improve patient <br>
				safety along the journey <br>
				<span class="bs1">before,</span> <span class="bs2">during,</span> <br>
				and <span class="bs3">after</span> surgery.
				<br>
				<div style="line-height: 24px;margin-top: 22px;">
					<router-link to="/project" class="default-btn" style="color: white;"><span class="ri-arrow-right-s-line"></span>More About Safest</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style>
	.safest-home-banner .wrap {
		min-height: 74vh;
		background-image: url('../../assets/safest/banner-safest.jpg');
		background-size: cover;
		background-position: center top;
		font-size: 52px;
		font-weight: bold;
		line-height: 125%;
		color: white;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.safest-home-banner span {
		display: inline-block;
		line-height: 110%;
	}

	.safest-home-banner .bs1{
		background-color: #FFBD59;
	}

	.safest-home-banner .bs2{
		background-color: #FF5A5A;
	}

	.safest-home-banner .bs3{
		background-color: #155B8D;
	}

	.safest-home-banner .default-btn span,
	.safest-home-banner .default-btn::before {
		background-color: #FFBD59;
	}

	@media only screen and (max-width : 991px) {
		.safest-home-banner .wrap {
			font-size: 32px;
		}
	}
</style>