<template>
    <div>
        <Preloader v-if="isLoading" />
        <router-view />
        <Footer />
        <GoTop />
    </div>
</template>

<script>
import Preloader from './components/Layout/Preloader'
import Footer from './components/Layout/Footer'
import GoTop from './components/Layout/GoTop'


export default {
    name: 'App',
    components: {
        Preloader,
        Footer,
        GoTop,
    },
    data() {
        return {
            isLoading: true
        }
    },
    mounted() {
        setTimeout(() => {
            this.isLoading = false
        }, 1000)
    },
}
</script>

<style type="text/css">
    #app {
        padding-top: 130px;
    }

    @media only screen and (max-width : 991px) {
        #app {
            padding-top: 00px;
        }
    }
</style>