<template>
	<div class="header-area header-style-two header-safest">
		<div :class="['navbar-area', {'is-sticky': isSticky}]">
			<div class="feliz-responsive-nav">
				<div class="container">
					<div class="feliz-responsive-menu">
						<div class="logo">
							<router-link to="/"><img src="../../assets/safest/logo-SAFEST_hi-res.png" alt="logo"></router-link>
						</div>

						<div 
							class="navbar-toggler" 
							@click="active = !active" :aria-pressed="active ? 'true' : 'false'"
							v-bind:class="{ 'active': button_active_state }"
							v-on:click="button_active_state = !button_active_state"
						>
						</div>

						<div class="others-option others-option-two">
							<a href="#footer" class="default-btn style-two">
								<span class="ri-edit-2-line"></span>
								Newsletter
							</a>
						</div>
					</div>
				</div>
			</div>

			<nav class="navbar navbar-expand-lg navbar-light" :class="{ toggler: active }">
				<div class="container">
					<router-link class="navbar-brand" to="/">
						<img src="../../assets/safest/logo-SAFEST_hi-res.jpg" width="240" alt="logo">
					</router-link>
					<div class="collapse navbar-collapse">
						<ul class="navbar-nav">
							<li class="nav-item">
								<router-link to="/project" class="nav-link" :class="{ 'dropdown-toggle': project_pages.length > 0 }">Project</router-link>
								<ul class="dropdown-menu" v-if="project_pages.length > 0">
									<li class="nav-item">
										<router-link to="/project" class="nav-link">About the project</router-link>
									</li>
									<li class="nav-item" v-for="(page, index) in project_pages" :key="index">
										<a :href="'/project/'+page.slug" class="nav-link" 
											
											v-if="page.slug != 'resources'"
											exact>
											<span v-html="page.title.rendered"></span>
										</a>

										<!-- <router-link to="/glossary" class="nav-link" 
											
											exact>
											<span>Glossary</span>
										</router-link> -->
									</li>
								</ul>
							</li>
							<li class="nav-item">
								<router-link to="/updates" class="nav-link">News</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/blog" class="nav-link">Blog</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/project/resources" class="nav-link">Resources</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/contact" class="nav-link">Contact</router-link>
							</li>
							<li class="nav-item">
								<a href="https://safest.fadq.org/web/guest/" target="_blank" class="nav-link"><span class="colored">Safest Platform</span></a>
							</li>
						</ul>
						<div class="others-option">
							<a href="#footer" class="default-btn style-two">
								<img src="../../assets/safest/newsletter-icon.png">
								Newsletter
							</a>
						</div>
					</div>
				</div>
			</nav>
		</div>
	</div>
</template>

<script>
import 'remixicon/fonts/remixicon.css'

export default {
	name: 'NavbarTwo',
	data(){
		return {
			isSticky: false,
			active: false,
			button_active_state: false,
			project_pages: []
		}
	},

	mounted(){
		const that = this
		window.addEventListener('scroll', () => {
			let scrollPos = window.scrollY
			if(scrollPos >= 100){
				that.isSticky = true
			} else {
				that.isSticky = false
			}
		})


		let wp = this.$wp

		wp.posts().categories(5).get((err, data) => {
			if (err) {
				// Gérer l'erreur
				console.error(err);
			} else {
				console.log('project_pages', data);
				this.project_pages = data;
			}
		});

		
	}
}
</script>

<style>
	.navbar {
		height: 130px;
	}
	.navbar-area {
		background-color: white !important;
	}


	.navbar .others-option .default-btn img{
		margin-right: 11px;
		height: 24px;
	}

	.header-area.header-style-two .navbar-area .navbar .others-option .default-btn{
		padding-left: 24px !important;
		padding-right: 24px !important;
	}

	/*.header-safest .nav-item, .others-option, .navbar-toggler{
		display: none !important;
	}*/

	.nav-link .colored{
		color: #155B8D;
		background-color: #FFBD59;
		display: inline-block;
		padding: 2px 5px;
		margin-top: -2px;
		border-radius: 3px;
	}

	@media only screen and (max-width : 991px) {
		.navbar {
			height: auto;
		}

		.logo{
			width: 40%;
			max-width: 180px;
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}
</style>