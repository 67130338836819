<template>
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content text-center">
                <h1>Get In <span style="color:#FF6666">Touch!</span></h1>
                <!-- <div class="row justify-content-center">
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class="ri-map-pin-2-line"></i>
                            </div>
                            <h3>Address</h3>
                            <p>Avenue Marnix 30, b17, 3rd floor <br> BE-1000 Brussels</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class="ri-time-line"></i>
                            </div>
                            <h3>Working Hours</h3>
                            <p>
                                Open Monday to Friday
                                <br>
                                From 09:00 PM to 16:00 AM
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class="ri-mail-unread-line"></i>
                            </div>
                            <h3>Email</h3>
                            <p>
                                <a href="#contact-form">Send us a message</a>
                                <br><br>
                            </p>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class="ri-phone-line"></i>
                            </div>
                            <h3>Toll Free</h3>
                            <p>
                                <a href="tel:+44587154756">+44 587 154756</a>
                                <br>
                                <a href="tel:+44587154756">+55 58715 4756</a>
                            </p>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- <div class="shape1"><img src="../../assets/images/shape/shape1.png" alt="image"></div>
        <div class="shape3"><img src="../../assets/images/shape/shape2.png" alt="image"></div> -->
    </div>
</template>

<script>
export default {
    name: 'ContactInfo'
}
</script>