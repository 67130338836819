<template>
    <div>
        <NavbarTwo />
        <div class="page-title-area">
            <div class="container">
                <div class="page-title-content text-center">
                    <h1><span style="color:#FFBD59">News</span></h1>
                    <h2>Updates & Events</h2>
                </div>
            </div>
        </div>
        <div class="case-studies-area pt-100 pb-70">
            <div class="container">
                <div class="case-studies-list">
                    <div class="row justify-content-center" >
                        <div v-for="(post, index) in posts" :key="index"  class="col-lg-5 col-md-6 single-case-studies-item-wrap">
                            <div class="single-case-studies-item bg-3D2F5D">
                                <div 
                                    class="round-image" 
                                    :style="{
                                        'background-image': `url(`+post.image+`)`,
                                    }">
                                </div>
                                <h3><router-link :to="get_link(post)" v-html="post.title.rendered"></router-link></h3>
                                <p class="display-date" v-html="post.display_date"></p>
                                <p v-html="post.excerpt.rendered"></p>
                                <router-link :to="get_link(post)" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                            </div>
                        </div>  
                    </div>
                    <div class="row justify-content-center mt-5 pt-5" >
                        <div class="text-center" v-if="!rest_post_invalid_page_number">
                            <a class="default-btn style-two" style="padding: 12px 30px !important; cursor: pointer;" @click="load_more_posts()">Load more</a>  
                        </div>
                        <div class="text-center" v-else>
                            <i>No more to load</i>
                        </div>                  
                    </div>
                </div>
            </div>
            
            <div class="shape6"><img width="140" src="../assets/safest/logo-safest-icon.png" alt="image"></div>
        </div>
    </div>
</template>

<script>
import NavbarTwo from '../components/Layout/NavbarTwoSafest'

export default {
    components: {
        NavbarTwo,
    },
    data(){
        return {
            page: 1,
            posts: [],
            rest_post_invalid_page_number: 0,
        }
    },
    mounted(){

        this.$metas( {
            title : 'Safest | News',
            description : 'Read all about Safest updates and discover next events',
        } )

        this.load_more_posts()

        //let wp = this.$wp

        /*wp.posts().perPage( 8 ).categories([3,4]).get( ( err, data ) => {
            
            this.posts = data

            this.posts.forEach( (post, index) => {

                let date = new Date(post.modified);
                //console.log( 'post', date.toDateString() );
                this.posts[index].display_date = date.toDateString()

                if( post.featured_media )
                {
                    wp.media().id(post.featured_media).get( ( err, data ) => {

                        let image = data.media_details.sizes.blog.source_url
                        this.posts[index].image = image
                    }); 
                }
            });
        });*/
    },
    methods : {
       get_link(post) {
            return '/news/' + post.slug
       },
       load_more_posts() {
        let wp = this.$wp

        wp.posts().perPage( 8 ).categories([3,4]).page(this.page).get((err, data) => {
            if (err) {
                // Gérer l'erreur ici
                if( err.code == 'rest_post_invalid_page_number' ) this.rest_post_invalid_page_number = 1
                console.error(err.code);
                return;
            }

            let new_posts = data;

            // Créer un tableau de promesses pour les requêtes d'images
            let imagePromises = new_posts.map((post, index) => {
                return new Promise((resolve, reject) => {

                    let date = new Date(post.modified);
                    //console.log( 'post', date.toDateString() );
                    new_posts[index].display_date = date.toDateString()

                    if (post.featured_media) {
                        wp.media().id(post.featured_media).get((err, data) => {
                            if (err) {
                                reject(err);
                            } else {
                                let image = data.media_details.sizes.blog.source_url;
                                new_posts[index].image = image;
                                resolve();
                            }
                        });
                    } else {
                        resolve(); // Résoudre immédiatement si aucun media n'est associé
                    }
                });
            });

            // Attendre que toutes les images soient chargées
            Promise.all(imagePromises).then(() => {
                // Fusionner les nouveaux posts avec les posts existants
                this.posts = [...this.posts, ...new_posts];

                // Incrémenter la page pour la prochaine requête
                this.page++;
            }).catch(error => {
                console.error("Erreur lors du chargement des images :", error);
            });
        });

       }
    }

}
</script>

<style>
    .round-image{
        overflow: hidden;
        width: 200px;
        height: 200px;
        
        margin: auto;
        margin-bottom: 50px;
        border-radius: 150px;
        background-size: cover;
        background-position: center center;

    }
</style>