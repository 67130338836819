<template>
    <div>
        <NavbarTwo />
        <div class="case-studies-details-area ptb-100">
            <div class="container">
                <div class="case-studies-details-header row align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="image">
                            <img id="project-cover-over" :src="image">
                            <img id="project-cover" src="../assets/safest/safest-project-cover.jpg" alt="image">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="content" v-if="title != ''">
                            <h1 v-html="title"></h1>
                            <h2 v-html="baseline"></h2>
                            <!-- <p>Working to improve patient safety along the journey<br> before, during, and after surgery.</p> -->
                        </div>
                    </div>
                </div>
                <div class="case-studies-details-desc" v-if="acf.chapters">
                    <div class="article-content">
                        <div class="row mb-40" v-for="(chapter, index) in acf.chapters" :key="index">
                            <div class="col-lg-5 col-md-12">
                                <h2><span :style="{color: chapter.color}" v-html="chapter.title"></span></h2>
                            </div>
                            <div class="col-lg-7 col-md-12">
                                <p class="content" v-html="chapter.text.replace(/(?:\r\n|\r|\n)/g, '<br>')">
                                    
                                </p>
                            </div>
                        </div>
                        <div class="row mb-40">
                            <div class="col-lg-12 col-md-12">
                                <h2><span :style="{color: colors[color_i]}">CONSORTIUM </span></h2>
                                <br><br>
                            </div>
                            <div id="project-consortium" class="col-lg-12 col-md-12">
                                <div class="content row mb-40">
                                    <div class="partner col-lg-4 col-md-6" v-for="(partner, index) in acf.consortium" :key="index">
                                        <img :src="partner.image">
                                        <h3 v-html="partner.partner_name"></h3>
                                        <h4 v-if="index == 0">Project Coordinator</h4>
                                        <p v-html='partner.text'></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavbarTwo from '../components/Layout/NavbarTwoSafest'



export default {
    data(){
        return {
            title : '',
            baseline: '',
            image: null,
            acf : {},
            color_i: 0,
            colors: ['#FFBD59', '#FF5757', '#155B8D'],
        }
    },
    components: {
        NavbarTwo,
    },
    mounted(){
        this.$metas( {
            title : 'Safest | The project',
            description : 'The European Commission estimates that 8% to 12% of patients admitted to hospitals in the EU suffer adverse events, many following surgical procedures. The results can be devastating in terms of loss of life and possible long-term disability.',
        } )

        let wp = this.$wp

        wp.pages().slug( 'the-project' ).get( ( err, data ) => {
            this.title = data[0].title.rendered
            this.baseline = data[0].content.rendered.replace(/(<([^>]+)>)/gi, "");
            this.acf = data[0].acf
            
            console.log(data[0]);

            if( data[0].featured_media )
            {
                wp.media().id(data[0].featured_media).get( ( err, data2 ) => {

                    let image = data2.media_details.sizes.full.source_url
                    this.image = image
                }); 
            }

            this.acf.chapters.forEach( (chapter, index) => {
                this.acf.chapters[index].color = this.colors[this.color_i]
                this.color_i ++;
                if( this.color_i > 2 )
                {
                    this.color_i = 0
                }
                
            })
            
            this.acf.consortium.forEach( (partner, index) => {

                if( partner.logo )
                {
                    wp.media().id(partner.logo).get( ( err, partner_data ) => {
                        let image = partner_data.media_details.sizes.full.source_url
                        this.acf.consortium[index].image = image
                    }); 
                }
            });
        })
    },
    methods: {
        
    }
}
</script>

<style>
    #project-cover {
        height: 450px;
        margin: auto;
        display: block;
    }

    #project-cover-over{
        position: absolute;
        margin: 36px 0px 0px 88px;
        border-radius: 250px;
        height: 410px;
    }

    #project-consortium .partner {
        padding: 0px 35px 70px;
    }

    #project-consortium .partner img {
        height: 60px;
        width: auto;
        display: block;
        margin: auto;
    }

    #project-consortium .partner h3{
        font-size: 18px;
        line-height: 130%;
    }

    #project-consortium .partner h4{
        font-size: 18px;
        line-height: 130%;
        background-color: #155B8D;
        display: inline-block;
        color: white;
        padding: 0 5px;
    }

    #project-consortium .partner p{
        font-size: 16px;
        line-height: 130%;
    }

    @media only screen and (max-width : 1400px) {
        #project-cover-over{
            margin: 36px 0px 0px 43px;
        }
    }

    @media only screen and (max-width : 1200px) {
        #project-cover{
            height: 400px;
        }
        #project-cover-over{
            margin: 32px 0px 0px 33px;
            height: 365px;
        }
    }
    
    @media only screen and (max-width : 991px) {
        #project-cover-over{
            margin: 32px 0px 0px 152px;
            height: 365px;
        }
    }

    @media only screen and (max-width : 767px) {
        #project-cover-over{
            margin: 32px 0px 0px 63px;
            height: 365px;
        }
    }

    @media only screen and (max-width : 575px) {
        #project-cover-over{
            margin: 6.8% 0px 0px 5%;
            height: auto;
            width: 80%;
        }

        #project-cover{
            height: auto;
        }
    }

    @media only screen and (max-width : 350px) {
        #project-consortium .partner img {
            height: auto;
            width: 90%;
        }
    }
</style>