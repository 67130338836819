<template>
    <div>
        <NavbarTwo />
        <ContactInfo />
        <GetInTouch />
    </div>
</template>

<script>
import NavbarTwo from '../Layout/NavbarTwoSafest'
import ContactInfo from '../Contact/ContactInfo'
import GetInTouch from '../Contact/GetInTouch'

export default {
    components: {
        NavbarTwo,
        ContactInfo,
        GetInTouch,
    },
    mounted(){
        this.$metas( {
            title : 'Safest | Contact',
            description : 'Get In Touch with Safest. Contact us with your details & ready to start with us.',
        } )
    }
}
</script>