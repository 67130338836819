<template>
    <div class="preloader">
        <div class="loader">
            <div class="loader-outter"></div>
            <div class="loader-inner"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Preloader'
}
</script>

<style type="text/css">
    .preloader{
        top: 0px;
    }
</style>