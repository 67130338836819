<template>
	<div class="ptb-100 updates-section" v-if="posts[0]">
		<div class="container" >
			<div class="section-title">
				<h2>Latest updates</h2>
			</div>
			<div class="case-studies-details-header row align-items-center justify-content-center">
				<div class="col-lg-6 col-md-12" id="news-home">
					<div>
						<img id="news-home-bg" src="../../assets/safest/news-home-bg.jpg" alt="image">
						<div id="news-home-cover">
							<!-- <img :src="image"> -->

							<div :style="{ backgroundImage: 'url(' + posts[0].image + ')' }"></div>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-md-12">
					<div class="content update" v-for="(post, index) in posts" :key="index">
						<h3 v-html="post.title.rendered"></h3>
						<p class="display-date" v-html="post.display_date"></p>
						<p v-html="post.excerpt.rendered"></p>
						<router-link :to="get_link(post)" class="default-btn style-two"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			posts: [],
		}
	},
	mounted() {

		let wp = this.$wp

		wp.posts().categories([3,4]).perPage( 3 ).get( ( err, data ) => {
			
			this.posts = data

			

			this.posts.forEach( (post, index) => {
				

				let date = new Date(post.modified);
				//console.log( 'post', date.toDateString() );
				this.posts[index].display_date = date.toDateString()

				if( post.featured_media )
				{
					wp.media().id(post.featured_media).get( ( err, data ) => {

						console.log(data.media_details);

						let image = data.media_details.sizes.full.source_url
						this.posts[index].image = image

						
					});	
				}
			});
			
		});
	},
	methods : {
		get_link(post) {
			return '/news/' + post.slug
		},
	}
}
</script>

<style>
	#news-home {
		height: 550px;
	}

	#news-home-bg {
		height: 550px;
		width: auto !important;
		max-width: none !important;
		position: absolute;
		right: 55%;
	}


	#news-home-cover {
		position: absolute;
		height: 395px;
		width: 395px;
		transform: rotate(45deg) translate(0, 110px);
		right: 55%;
		border-radius: 0 19px 0 0 ;
		overflow: hidden;
	}

	@media only screen and (max-width : 991px) {
		#news-home-bg, #news-home-cover {
			margin-top: 45px;
			right: 22%;
		}
	}

	@media only screen and (max-width : 666px) {
		#news-home-bg, #news-home-cover {
			margin-top: 45px;
			right: 0%;
		}

		#news-home{
			transform: scale(0.8);
		}
	}
	#news-home-cover div {
		position: absolute;
		background-size: cover;
		transform: rotate(-45deg) translate(0px, -117px);
		transform-origin: center;
		height: 560px;
		width: 560px;

	}

	.updates-section .default-btn.style-two,
	.updates-section .default-btn.style-two:hover{
		background-color: #FFBD59;
	}

	.updates-section .update{
		margin-bottom: 15px;
	}
</style>