<template>
	<div>
		<NavbarTwo />
		<div class="page-title-area">
			<div class="container">
				<div class="page-title-content text-center">
					<h1><span style="color:#FF6666">Blog</span></h1>
					<h2>Partner Perspectives</h2>
				</div>
			</div>
		</div>
		<div class="blog-area ptb-100">
			<div class="container">
				<div class="row justify-content-center" v-if="posts.length == 0">
					Blog posts coming soon !
				</div>
				<div class="row justify-content-center">
					<div 
						v-for="(post, index) in posts" :key="index"
						class="col-lg-4 col-md-6 col-sm-6" >
						<div class="single-blog-post">
							<div class="image">
								<router-link :to="get_link(post)" class="d-block" v-if="post.image">
									<img :src="post.image" alt="image">
								</router-link>
								<span class="date" v-html="get_date(post)" ></span>
								<div style="width:1px;height: 1px;"></div>
							</div>
							<div class="content">
								<h3><router-link :to="get_link(post)" v-html="post.title.rendered"></router-link></h3>
								<div class="excerpt" v-html="post.excerpt.rendered"></div>
								<router-link :to="get_link(post)" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
							</div>
						</div>
					</div>
				</div>
				<div class="row justify-content-center mt-5 pt-5" >
					<div class="text-center" v-if="!rest_post_invalid_page_number">
						<a class="default-btn style-two" style="padding: 12px 30px !important; cursor: pointer;" @click="load_more_posts()">Load more</a>	
					</div>
					<div class="text-center" v-else>
						<i>No more to load</i>
					</div>					
				</div>
			</div>
		</div>
	</div>
</template>

<style type="text/css">
	.blog-area .default-btn::before,
	.blog-area .default-btn span{
		background-color: #FFBD59;
	}
	.blog-area .default-btn{
		color: #155B8D;
	}

	.blog-area h3 a {
		color: #155B8D;
	}

	.single-blog-post .excerpt p {
		margin-bottom: 15px;
	}
	
</style>

<script>
import NavbarTwo from '../components/Layout/NavbarTwoSafest'


export default {
	components: {
		NavbarTwo,
	},
	data(){
		return {
			page: 1,
			posts: [],
			rest_post_invalid_page_number: 0,
		}
	},
	mounted () {

		this.$metas( {
			title : 'Safest | Blog',
			description : 'Read all about our Partner Perspectives',
		} )

		this.load_more_posts()
	},
	methods : {
		get_date(post) {
			
			let date = new Date(post.date)
			var options = { year: 'numeric', month: 'long', day: 'numeric' };
			let formated = date.toLocaleDateString("en-EN", options)

			return formated
		},
		get_link(post) {

			return '/blog/'+post.slug
		},
		load_more_posts() {
			let wp = this.$wp

			wp.posts().perPage(9).categories(1).page(this.page).get((err, data) => {
				if (err) {
					// Gérer l'erreur ici
					if( err.code == 'rest_post_invalid_page_number' ) this.rest_post_invalid_page_number = 1
					console.error(err.code);
					return;
				}

				let new_posts = data;

				// Créer un tableau de promesses pour les requêtes d'images
				let imagePromises = new_posts.map((post, index) => {
					return new Promise((resolve, reject) => {
						if (post.featured_media) {
							wp.media().id(post.featured_media).get((err, data) => {
								if (err) {
									reject(err);
								} else {
									let image = data.media_details.sizes.blog.source_url;
									new_posts[index].image = image;
									resolve();
								}
							});
						} else {
							resolve(); // Résoudre immédiatement si aucun media n'est associé
						}
					});
				});

				// Attendre que toutes les images soient chargées
				Promise.all(imagePromises).then(() => {
					// Fusionner les nouveaux posts avec les posts existants
					this.posts = [...this.posts, ...new_posts];

					// Incrémenter la page pour la prochaine requête
					this.page++;
				}).catch(error => {
					console.error("Erreur lors du chargement des images :", error);
				});
			});

			
		}
	}
}
</script>