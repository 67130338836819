<template>
    <div class="partner-area pt-100 about-short">
        <div class="container">
            <div class="section-title style-two">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-12">
                        <h2>About <br>the project</h2>
                    </div>
                    <div class="col-lg-7 col-md-12">
                        <p>SAFEST is an ambitious 4-year project funded under the new cycle of the EU’s framework for research and innovation, Horizon Europe. <br><br>
                        When considering surgical safety, SAFEST will look at the entire journey before, during, and after surgery (often referred 
                        to as perioperative safety and care). Especially considering how several studies have shown that most adverse events linked to surgery 
                        occur outside the operating room.<br><br>

                        Our project seeks to play a decisive role in improving patient safety and it will do so by (1) identifying and agreeing on a unified set of perioperative practices based on evidence, and (2) promoting their implementation across Europe involving healthcare professionals, patients, and other stakeholders.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent ({
    
    components: {
        
    },
})
</script>

<style type="text/css">
    
</style>