<template>
    <div>
        <NavbarTwo />
        <PageTitle pageMainTitle="Terms Of Service" pageTitle="Terms Of Service" />
        <TermsConditionsContent/>
    </div>
</template>

<script>
import NavbarTwo from '../Layout/NavbarTwoSafest'
import PageTitle from '../Common/PageTitle'
import TermsConditionsContent from '../TermsConditions/TermsConditionsContent'

export default {
    name: 'TermsOfServicePage',
    components: {
        NavbarTwo,
        PageTitle,
        TermsConditionsContent
    }
}
</script>