<template>
    <div class="features-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>The SAFEST approach will be</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6 col-md-6 single-features-box-wrap">
                    <div class="single-features-box">
                        <div class="icon">
                            <img src="../../assets/safest/Patient-centred.png" alt="image">
                        </div>
                        <span class="sf1">Patient-centred <br> and evidence-based </span>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 single-features-box-wrap">
                    <div class="single-features-box">
                        <div class="icon">
                            <img src="../../assets/safest/Collaborative.png" alt="image">
                        </div>
                        <span class="sf2">Collaborative</span>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 single-features-box-wrap">
                    <div class="single-features-box">
                        <div class="icon">
                            <img src="../../assets/safest/Scalable.png" alt="image">
                        </div>
                        <span class="sf3">Scalable</span>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 single-features-box-wrap">
                    <div class="single-features-box">
                        <div class="icon">
                            <img src="../../assets/safest/Adaptable.png" alt="image">
                        </div>
                        <span class="sf4">Adaptable</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BeEverywhereYour'
}
</script>

<style scoped>
    .icon img{
        height: 110px;
    }
    .single-features-box-wrap {
        margin-bottom: 15px;
    }
    .single-features-box {
        height: 100%;
        margin-bottom: 0px;
    }

    .single-features-box span{
        display: inline-block;
        color: white;
        padding: 5px 14px;
        font-weight: 700;
    }

    .sf1 {
        background: #155B8D;
    }

    .sf2 {
        background: #FF5757;
    }

    .sf3 {
        background: #FFBD59;
    }

    .sf4 {
        background: #FFBD59;
    }
</style>