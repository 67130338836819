<template>
    <div class="partner-area pt-100">
        <div class="container">
            <div class="section-title">
                <div class="row align-items-center">
                    <h2>Project Partners</h2>
                </div>
            </div>

            <div class="partner-slides-safest">
                <!-- <carousel
                    :autoplay="5000"
                    :settings='settings'
                    :breakpoints='breakpoints'
                > -->
                    <!-- <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    > -->
                    <div 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="">
                            <div class="d-block">
                                <img :src="slide.image" alt="image">
                            </div>
                        </div>
                    </div>
                    <!-- </slide> -->
                    
                    <!-- <template #addons>
                    </template> -->
                <!-- </carousel> -->
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
/*import { Carousel, Slide  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';*/

export default defineComponent ({
    name: 'ATrustedPartner',
    components: {
        /*Carousel,
        Slide,*/
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 0,
                image: require('../../assets/safest/1c_FAD.jpg'),
            },
            {
                id: 1,
                image: require('../../assets/safest/2_NIVEL.jpg'),
            },
            {
                id: 2,
                image: require('../../assets/safest/NOVA.png'),
            },
            {
                id: 3,
                image: require('../../assets/safest/4_IQRadboud.jpg'),
            },
            {
                id: 4,
                image: require('../../assets/safest/5_SENSAR.jpg'),
            },
            {
                id: 5,
                image: require('../../assets/safest/6_UTARTU.jpg'),
            },
            {
                id: 6,
                image: require('../../assets/safest/7_OM.jpg'),
            },
            {
                id: 7,
                image: require('../../assets/safest/8_HOPE.jpg'),
            },
            {
                id: 8,
                image: require('../../assets/safest/9b_ESAIC.jpg'),
            },
            {
                id: 9,
                image: require('../../assets/safest/10_SAK.jpg'),
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 2,
                snapAlign: 'left',
			},
            576: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 6,
                snapAlign: 'center',
            },
        },
    }),
})
</script>

<style type="text/css">
    .partner-slides-safest {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .partner-slides-safest img {
        max-height: 80px;
        width: auto !important;
        max-width: calc(100% - 120px);
        margin: 60px;
    }
</style>