<template>
    <div class="terms-conditions ptb-100">
        <div class="container">
            <div class="single-privacy">
                <div v-html="content"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PrivacyPolicyContent',
    data(){
        return {
            content: ''
        }
    },
    mounted () {

        let wp = this.$wp

        wp.pages().slug( 'privacy-policy' ).get( ( err, data ) => {
            this.content = data[0].content.rendered
        })
    }
}
</script>