<template>
    <div>
        <NavbarTwo />
        <PageTitle pageMainTitle="Privacy Policy" pageTitle="Privacy Policy" />
        <PrivacyPolicyContent />
    </div>
</template>

<script>
import NavbarTwo from '../Layout/NavbarTwoSafest'
import PageTitle from '../Common/PageTitle'
import PrivacyPolicyContent from '../PrivacyPolicy/PrivacyPolicyContent'

export default {
    name: 'PrivacyPolicyPage',
    components: {
        NavbarTwo,
        PageTitle,
        PrivacyPolicyContent
    },
    mounted(){
        this.$metas( {
            title : 'Safest | Privacy Policy',
            description : 'Read all about our Privacy Policy.',
        } )
    }
}
</script>