<template>
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content text-center">
                <h1>{{pageMainTitle}}</h1>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageTitle',
    props: ['pageMainTitle', 'pageTitle']
}
</script>