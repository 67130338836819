<template>
    <footer id="footer" class="footer-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <router-link to="/" class="d-inline-block logo">
                            <img width="170" src="../../assets/safest/logo-SAFEST_hi-res.png" alt="logo">
                        </router-link>
                        
                        <NewsletterRegister />
                        
                        <!-- <div class="newsletter-form">
                            <p>SUBSCRIBE TO OUR NEWSLETTER (available soon)</p>
                            <form data-toggle="validator">
                                <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off" disabled>
                                <button type="submit" disabled>Subscribe Now <i class="ri-send-plane-line"></i></button>
                                <div class="form-check" style="margin-top:8px">
                                    <input type="checkbox" class="form-check-input" id="checkme" disabled> 
                                    <label class="form-check-label" for="checkme">
                                        Accept 
                                        <router-link to="/terms-conditions">
                                            <strong>Terms of Services</strong>
                                        </router-link> 
                                        and 
                                        <router-link to="/privacy-policy">
                                            <strong>Privacy Policy</strong>
                                        </router-link> 
                                    </label>
                                </div>
                                <div id="validator-newsletter" class="form-result"></div>
                            </form>
                        </div> -->
                        <ul class="social-links">
                            <li><a href="https://twitter.com/safestsurgery" target="_blank">
                                <img src="../../assets/safest/twitter.jpg">
                            </a></li>
                            <li><a href="https://www.linkedin.com/showcase/safest-eu-project/" target="_blank">
                                <img src="../../assets/safest/LinkedIn_icon.jpg">
                            </a></li>
                            <li><a href="https://bsky.app/profile/safestsurgery.bsky.social" target="_blank">
                                <img src="../../assets/safest/bsky.app.jpg">
                            </a></li>
                            <li><a href="https://www.youtube.com/channel/UCWJN2iyVcyPxDIvHmY9GAQA" target="_blank">
                                <img src="../../assets/safest/youtube.jpg">
                            </a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <img width="320" src="../../assets/safest/EN-Funded-by-the-EU-POS.jpg">
                        <p></p>
                        <p style="font-size: 14px !important;">
                            Funded by the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union or European Commission [granting authority]. Neither the European Union nor the granting authority can be held responsible for them. This project is financed by the European Union’s Horizon Europe research and innovation programme under grant agreement No. 101057825 (SAFEST, Improving quality and patient SAFEty in surgical care through STandardisation and harmonisation of perioperative care in Europe).
                        </p>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-6 col-md-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Quick Links</h3>
                        <ul class="quick-links">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/project">Project</router-link></li>
                            <li><router-link to="/contact">Contact</router-link></li>
                            <li><router-link to="/glossary">Glossary</router-link></li>
                            <li>
                                <a href="https://safest.fadq.org/web/guest/" target="_blank"><span class="colored">Safest Platform</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div class="col-lg-2 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <h3>Contact Info</h3>
                        <ul class="footer-contact-info">
                            <li><router-link to="/contact">Send us a message</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="copyright-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <p>© {{currentYear}} <strong>SAFEST</strong> All Rights Reserved | Website created by <a target="_blank" href="https://odoacer.be">Odoacer</a></p>
                    </div>
                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <ul>
                            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            <!-- <li><router-link to="/terms-conditions">Terms & Conditions</router-link></li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>

import NewsletterRegister from '../safest/NewsletterRegister'
export default {
    components : {
        NewsletterRegister
    },
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>

<style>
    .social-links li{
        width: 32px;
    }

    .social-links li img{
        border-radius: 5px;
    }
</style>